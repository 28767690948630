
import React, { useContext, useEffect,useRef } from "react";
import { Power4, TweenMax } from "gsap";

import { Error, PageContext, SEO } from "../layout";

import sc from "../layout/partials/ComponentTemplate.module.scss";

const PageNotFound = () => {
  
  const elCover = useRef(null);
  const page = useContext(PageContext);

  useEffect(() => {

    page.toggleCurrentRoute(window.location.pathname);

    TweenMax.set(elCover.current, { clearProps: "all" });
    TweenMax.fromTo(elCover.current.firstElementChild, .3, {
      x: "0%",
    }, {
      delay: .1,
      ease: Power4.easeInOut,
      onComplete: () => TweenMax.set(elCover.current, { zIndex: -1 }),
      x: "100%",
    });
  }, []);

  return (
    <>
      <SEO title="404" />
      <Error>
        404: Page not found
      </Error>
      <div className={sc.cover} ref={elCover}>
        <div />
      </div>
    </>
  );
};

export default PageNotFound;
